<template>
    <div class="__header-cardapio container">
        <div class="row">
          <div class="col-12 button-container">
            <button @click="back" class="btn btn-primary">Voltar</button>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardapioHeader',
  methods:{
    back(){
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
  .btn-primary,  .bg-primary{
    background-color: #FF7020 !important;
    border-color: #FF7020 !important;
  }
  .__header-cardapio{
    margin-bottom: 2vh;
    padding: 1vh;
    border-bottom: 1px solid #CDCDCD;
  }
  .button-container{
    padding-top: auto;
  }
  .row {
    --bs-gutter-x: 0;
  }
</style>