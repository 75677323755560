<template>
  <div class="__container">
    <router-view/>
  </div>
</template>

<style lang="scss">
  .__container{
    margin: auto;
    padding: 2vh;
    width: 100%;
    min-height: 100vh;
  }


  .btn-primary,  .bg-primary{
    background-color: #FF7020 !important;
    border-color: #FF7020 !important;
  }
</style>
