<template>
  <div class="__container-cardapio">
    <CardapioHeader />
    <form @submit="createCardapio">

    <div class="__title-cardapio card-container shadow p-3 mb-2 bg-white rounded">
      <small><label for="title">Título:</label></small>
      <input type="text" class="form-control" id="title" name="title" v-model="title" placeholder="Titulo">
      <div><span class="error">{{ errors.title }}</span></div>
      <small><label for="state">Situação:</label></small>
      <select id="state" name="state" class="form-control" v-model="state">
        <option value="1">Disponível</option>
        <option value="0">Encerrado</option>
      </select>
      <span class="error">{{ errors.state }}</span>
      <div class="form-check">
        <br>
        <input class="form-check-input" type="checkbox" value="" id="check" v-model="check" >
        <label class="form-check-label" for="flexCheckDefault">
          Deixar menu online
        </label>
      </div>
    </div>

    <!-- PRATOS -->
    <div class="__lunch card-container shadow p-3 mb-2 bg-white rounded">
      <div class="card-container p-2 mb-2 bg-primary rounded font-white row">Pratos</div>
      <span class="error">{{ errors.lunchs }}</span>
      <div v-for="(lunch, index) in items" :key="index">
        <div v-if="lunch.type == 'food'" class="__lunch-items card-container p-3 mb-2 bg-light rounded row">
          <div class="col-10" :style="{ opacity: lunch.situation === 0 ? '0.5' : '1' }">
            <p style="display: none;"><strong>{{lunch.id}}</strong></p>
            <p><strong>{{lunch.title}}</strong></p>
            <p><i>{{lunch.description}}</i></p>
            <p><i>{{ lunch.situation === 1 ? "Disponível" : "Inativo" }}</i></p>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="editLunch(index)" class="btn btn-warning radius-50">Editar</button>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="removeLunch(index)" class="btn btn-danger radius-50" style="margin-left: 15px;">x</button>
          </div>
        </div>
      </div>

      <div v-show="modalLunch" class="__lunch-modal card card-container p-3 mb-2 bg-white rounded">
        <div class="container-close">
          <button type="button" @click="closeLunch" class="btn btn-danger radius-50">x</button>
        </div>
        <small><label for="launch">Prato:</label></small>
        <input name="launch" type="text" class="form-control" v-model="inputNameLunch" >
        <small><label for="description">Descrição:</label></small>
        <input name="description" type="text" class="form-control" v-model="inputDescriptionLunch" >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="checkLunch" value=true v-model="situationLunch">
          <label class="form-check-label" for="checkLunch">Disponível</label>
        </div>
        <div class="container-add" v-if="btnEdit === ''">
          <button type="button" @click="saveLunch()" class="btn btn-success">Adicionar</button>
        </div>
        <div class="container-add" v-if="btnEdit !== ''">
          <button type="button" @click="saveLunch(btnEdit)" class="btn btn-success">Editar</button>
        </div>
      </div>

      <div class="container-plus">
        <button type="button" @click="addLunch" class="btn btn-primary radius-50">+</button>
      </div>
    </div>

    <!-- PORÇÕES -->
    <div class="__lunch card-container shadow p-3 mb-2 bg-white rounded">
      <div class="card-container p-2 mb-2 bg-primary rounded font-white row">Porções</div>
      <div v-for="(portion, index) in items" :key="index">
        <div v-if="portion.type == 'portion'" class="__lunch-items card-container p-3 mb-2 bg-light rounded row">
          <div class="col-10" :style="{ opacity: portion.situation === 0 ? '0.5' : '1' }">
            <p style="display: none;"><strong>{{portion.id}}</strong></p>
            <p><strong>{{portion.title}}</strong></p>
            <p><i>{{portion.description}}</i></p>
            <p><i>{{ portion.situation === 1 ? "Disponível" : "Inativo" }}</i></p>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="editPortion(index)" class="btn btn-warning radius-50">Editar</button>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="removePortion(index)" class="btn btn-danger radius-50" style="margin-left: 15px;">x</button>
          </div>
        </div>
      </div>

      <div v-show="modalPortion" class="__lunch-modal card card-container p-3 mb-2 bg-white rounded">
        <div class="container-close">
          <button type="button" @click="closePortion" class="btn btn-danger radius-50">x</button>
        </div>
        <small><label for="launch">Porção:</label></small>
        <input name="launch" type="text" class="form-control" v-model="inputNamePortion" >
        <small><label for="description">Descrição:</label></small>
        <input name="description" type="text" class="form-control" v-model="inputDescriptionPortion" >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="checkLunch" value=true v-model="situationPortion">
          <label class="form-check-label" for="checkLunch">Disponível</label>
        </div>
        <div class="container-add" v-if="btnEdit === ''">
          <button type="button" @click="savePortion()" class="btn btn-success">Adicionar</button>
        </div>
        <div class="container-add" v-if="btnEdit !== ''">
          <button type="button" @click="savePortion(btnEdit)" class="btn btn-success">Editar</button>
        </div>
      </div>

      <div class="container-plus">
        <button type="button" @click="addPortion" class="btn btn-primary radius-50">+</button>
      </div>
    </div>


    <!-- BEBIDAS -->
    <div class="__drinks card-container shadow p-3 mb-2 bg-white rounded">
      <div class="card-container p-2 mb-2 bg-primary rounded font-white row">Bebidas</div>
      <div v-for="(drink, index) in items" :key="index">
        <div v-if="drink.type == 'drink'" class="__lunch-items card-container p-3 mb-2 bg-light rounded row" >
          <div class="col-10"  :style="{ opacity: drink.situation === 0 ? '0.5' : '1' }">
            <p><strong>{{drink.title}}</strong></p>
            <p><i>{{drink.description}}</i></p>
            <p><i>{{ drink.situation === 1 ? "Disponível" : "Inativo" }}</i></p>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="editDrink(index)" class="btn btn-warning radius-50">Editar</button>
          </div>
          <div class="col-1 container-close">
            <button type="button" @click="removeDrink(index)" class="btn btn-danger radius-50" style="margin-left: 15px;">x</button>
          </div>
        </div>
      </div>

      <div v-show="modalDrink" class="__lunch-drink card card-container p-3 mb-2 bg-white rounded">
        <div class="container-close">
          <button type="button" @click="closeDrink" class="btn btn-danger radius-50">x</button>
        </div>
        <small><label for="drink">Bebida:</label></small>
        <input name="drink" type="text" class="form-control" v-model="inputNameDrink" >
        <small><label for="descrptionDrink">Descrição:</label></small>
        <input name="descrptionDrink" type="text" class="form-control" v-model="inputDescriptionDrink" >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="checkDrink" value="1" v-model="situationDrink">
          <label class="form-check-label" for="checkDrink">Disponível</label>
        </div>
        <div class="container-add" v-if="btnEdit === ''">
          <button type="button" @click="saveDrink()" class="btn btn-success">Adicionar</button>
        </div>
        <div class="container-add" v-if="btnEdit !== ''">
          <button type="button" @click="saveDrink(btnEdit)" class="btn btn-success">Editar</button>
        </div>
      </div>

      <div class="container-plus">
        <button type="button" @click="addDrink" class="btn btn-primary radius-50">+</button>
      </div>
    </div>

    <div class="__save">
      <div class="card-container">
        <button type="submit" class="btn btn-success btn-save" >{{ mode === 'edit' ? 'Editar' : 'Salvar' }}</button>
      </div>
    </div>

    </form>
  </div>
</template>

<script>
  import CardapioHeader from '../components/cardapio/CardapioHeader.vue';
  import { api } from  '@/services.js';

  export default {
    name: 'CardapioView',
    data(){
      return{
        lunchs: [],
        portions: [],
        drinks: [],
        items: [],
        title: "",
        state: "",
        check: "",
        menu_id: "",

        modalLunch: false,
        modalPortion: false,
        modalDrink: false,

        inputNameLunch: "",
        inputNamePortion: "",
        inputNameDrink: "",

        inputDescriptionLunch: "",
        inputDescriptionPortion: "",
        inputDescriptionDrink: "",

        situationLunch: 0,
        situationPortion: 0,
        situationDrink: 0,

        routeId: null,
        mode: "",
        active: '',
        errors: {
              title: "",
              state: "",
              lunchs: ""
        },
        btnEdit: ''
      }
    },
    components: {
      CardapioHeader
    },
    methods: {
      //Lunch
      addLunch(){
        this.closeDrink()
        this.closePortion()
        this.modalLunch = true
      },
      closeLunch(){
        this.modalLunch = false
        this.inputNameLunch = ''
        this.inputDescriptionLunch = ''
        this.situationLunch = false;
        this.btnEdit = ''
      },
      editLunch(id){
        this.closeDrink()
        this.closePortion()
        this.modalLunch = true
        this.inputNameLunch = this.items[id].title
        this.inputDescriptionLunch = this.items[id].description
        if(this.items[id].situation === 1){
          this.situationLunch = true;
        }
        this.btnEdit = id;
      },
      removeLunch(id){
        this.items.splice(id,1)
      },
      clearLunch(){
        this.inputNameLunch = ''
        this.inputDescriptionLunch = ''
      },

      //Portion
      addPortion(){
        this.closeDrink()
        this.closeLunch()
        this.modalPortion = true
      },
      closePortion(){
        this.modalPortion = false
        this.inputNamePortion = ''
        this.inputDescriptionPortion = ''
        this.situationPortion = false;
        this.btnEdit = ''
      },
      editPortion(id){
        this.closeDrink()
        this.closeLunch()
        this.modalPortion = true
        this.inputNamePortion = this.items[id].title
        this.inputDescriptionPortion = this.items[id].description
        if(this.items[id].situation === 1){
          this.situationPortion = true;
        }
        this.btnEdit = id;
      },
      removePortion(id){
        this.items.splice(id,1)
      },
      clearPortion(){
        this.inputNamePortion = ''
        this.inputDescriptionPortion = ''
      },

      //Drink
      addDrink(){
        this.closeLunch()
        this.closePortion()
        this.modalDrink = true
      },
      closeDrink(){
        this.modalDrink = false
        this.inputNameDrink = ''
        this.inputDescriptionDrink = ''
        this.situationDrink = false;
        this.btnEdit = ''
      },
      editDrink(id){
        this.closeLunch()
        this.closePortion()
        this.modalDrink = true
        this.inputNameDrink = this.items[id].title
        this.inputDescriptionDrink = this.items[id].description
        if(this.items[id].situation === 1){
          this.situationDrink = true;
        }
        this.btnEdit = id;
      },
      removeDrink(id){
        this.items.splice(id,1)
      },
      clearDrink(){
        this.inputNameDrink = ''
        this.inputDescriptionDrink = ''
      },

      //Save
      saveLunch(id=''){
        if(id === ''){

          let sit = ''
          if(this.situationLunch === true){
            sit = 1
          }else{
            sit = 0
          }
          let data = {
            title: this.inputNameLunch,
            description: this.inputDescriptionLunch,
            type: "food",
            situation: sit,
          }
          this.items.push(data)
          this.closeLunch()
          this.clearLunch()
        }else{
          this.items[id].title = this.inputNameLunch
          this.items[id].description = this.inputDescriptionLunch
          if(this.situationLunch === true){
            this.items[id].situation = 1
          }else{
            this.items[id].situation = 0
          }
          this.btnEdit = ''
          this.closeLunch()
          this.clearLunch()
        }
        
      },
      savePortion(id=''){
        if(id === ''){

          let sit = ''
          if(this.situationPortion === true){
            sit = 1
          }else{
            sit = 0
          }
          let data = {
            title: this.inputNamePortion,
            description: this.inputDescriptionPortion,
            type: "portion",
            situation: sit,
          }
          this.items.push(data)
          this.closePortion()
          this.clearPortion()
        }else{
          this.items[id].title = this.inputNamePortion
          this.items[id].description = this.inputDescriptionPortion
          if(this.situationPortion === true){
            this.items[id].situation = 1
          }else{
            this.items[id].situation = 0
          }
          this.btnEdit = ''
          this.closePortion()
          this.clearPortion()
        }
        
      },
      saveDrink(id=''){

        let sit = ''
          if(this.situationDrink === true){
            sit = 1
          }else{
            sit = 0
        }

        if(id === ''){
          let data = {
            title: this.inputNameDrink,
            description: this.inputDescriptionDrink,
            type: "drink",
            situation: sit
          }
          this.items.push(data)
          this.closeDrink()
          this.clearDrink()
        }else{
          this.items[id].title = this.inputNameDrink
          this.items[id].description = this.inputDescriptionDrink
          if(this.situationDrink === true){
            this.items[id].situation = 1
          }else{
            this.items[id].situation = 0
          }
          this.btnEdit = ''
          this.closeDrink()
          this.clearDrink()
        }
      },

      //Post
      async createCardapio(e){

        e.preventDefault()        

        this.errors = {}
        if (!this.title) {
          this.errors.title = 'Insira um título para continuar.'
        }
        if (!this.state) {
          this.errors.state = 'Selecione a Situação desejada.'
        }
        if (!this.items[0]) {
          this.errors.lunchs = 'A inclusão de um prato é obrigatória'
        }

        if (Object.keys(this.errors).length === 0) {

          const dataForm = {
            name: String(this.title),
            status: Boolean(this.state),
            items: this.items
          }

          if(this.mode === 'edit'){

            let r = this.$route.params.id;
            //console.log(r)
            api.put('/menu/'+r, dataForm);
            //console.log(res)
          }else{
            api.post('/menu', dataForm);
            //console.log(res)
          }

          //Existe
          if(this.active != ""){
            if(this.check == true){

              if(this.active != this.menu_id){

                const sendCheck = {
                  menu_id: this.menu_id
                }
                api.post('/order', sendCheck);
              }
            }else{
              //Se não está chegado mas era o ativo, desabilito
              if(this.active == this.menu_id){
                //desabilito todos
                api.get('/deactivate');
              }
            }
            
          }else{//Novo

            if(this.check == true){
              //Pego Id do ultimo menu e ativo.
              let x = await api.get('/lastMenu');
              let last = x.data.id; 
              const sendCheck = {
                menu_id: last
              }
              api.post('/order', sendCheck);
            }
          }
          
          alert("Salvo com sucesso !")
          this.$router.push('/')
        }
        
      },
      async getData(id){

        let res = await api.get('/menu/'+id);

        this.title = res.data.name
        this.state = res.data.status
        this.items = res.data.items
        this.menu_id = res.data.id;
        },
      async activeMenu(){

        let order = await api.get('/orderActive');
        this.active = order.data.menu_id;
        let id = this.$route.params.id;

        if(id == this.active){
          this.check = true
        }

      },
    },
    mounted() {
      let r = this.$route.params.id;
      if(r != null){
        this.getData(r)
        this.mode = 'edit'
        this.activeMenu()
      }      
    }
  }
</script>
<style lang="scss">
  .card-body{
    min-height: 180px !important;
  }
</style>
<style lang="scss" scoped>
  .btn-primary,  .bg-primary{
    background-color: #FF7020 !important;
    border-color: #FF7020 !important;
  }
  .radius-50{
    border-radius: 50px;
  }
  .container-close {
    text-align: right;
  }

  .container-close .btn{
    --bs-btn-padding-x: 6px;
    --bs-btn-padding-y: 2px;
    font-size: 9px;
  }
  .container-add{
    margin-top: 1vh;
    text-align: left;
  }
  .container-plus{
    text-align: center;
  }
  .__save{
    display: inline-block;
    width: 100%;
    margin-top: 1vh;
  }
  .btn-save{
    min-width: 100%;
  }
  .error{
    color: red;
  }
  .font-white{
    color: #FFF;
  }
</style>