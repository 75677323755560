import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CardapioView from '../views/CardapioView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cardapio/new',
    name: 'cardapio/new',
    component: () => import(/* webpackChunkName: "about" */ '../views/CardapioView.vue')
  },
  {
    path: '/cardapio/edit/:id',
    name: 'cardapio/edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/CardapioView.vue')
  },
  {
    path: '/request/:token',
    name: 'request',
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestView.vue')
  },
  {
    path: '/success/:token',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue')
  },
  {
    path: '/pedidos/:id',
    name: 'pedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
