<template>
  <div class="container __cardapio-container">
    <HomeHeader />
    <div class="__header bg-primary p-2 rounded" id="title-header">
      <div class="row">
          <div class="col-10">
            <div class="text-monospace">Restaurantes</div>
          </div>
          <div class="col-2">
            <div class="text-monospace">ID</div>
          </div>
      </div>
    </div>

    <div class="__list row">
      <div class="col-12 col-sm-12 col-md-6" v-for="d in data" :key="d.id">
        <div class="card shadows p-3 mb-2 bg-white rounded" :class="{'custom-shadow': d.id == this.active}">
          <div class="card-body">
            <p class="card-id">{{d.id}}</p>
            <h5 class="card-title">{{d.name}}</h5>
            <p class="card-text-on" v-if="d.id == this.active">Cardápio Online</p>
            <div class="card-button">
              <button @click="checkList(d.id)" class="d-inline-block btn btn-primary">Pedidos</button>
              <button @click="putCardapio(d.id)" class="d-inline-block btn btn-primary separa">Editar</button>
            </div>
          </div>
        </div>
          <div class="col-1">
            <p></p>
          </div>

      </div>
    </div>

    
  </div>
</template>

<script>
import HomeHeader from "../components/home/HomeHeader.vue";
import { api } from  '@/services.js';

export default {
  name: 'HomeView',
  components: {
    HomeHeader,
  },
  data(){
    return{
      data: [],
      active: ''
    }
  },
  mounted() {
    this.getCardapio();
    this.activeMenu();
  },
  methods: {
    
    async getCardapio(){

      let res = await api.get('/menu');
      this.data = res.data.data;

    },
    async activeMenu(){

      let order = await api.get('/orderActive');
      this.active = order.data.menu_id;

    },
    putCardapio(id){
      this.$router.push('/cardapio/edit/'+id);
    },
    checkList(id){
      this.$router.push('/pedidos/'+id);
    }
  }
}
</script>

<style lang="scss">

  
  .card-container{
    margin: auto;
    max-width: 500px;
  }

  .__header{
    color: #FFF;
  }

  #title-header{
    background-color: #FF7020 !important;
    border-color: #FF7020 !important;
  }

  .__list{
    margin-top: 2vh !important;
  }

  .card-id{
    text-align: right;
  }

  .card-button{
    text-align: left;

    .separa{
      margin-left: 10px;
    }
  }

  .card-text-on{
    font-size: 12px;
    color: #FF7020
  }

  .shadows{
    -webkit-box-shadow: 10px 10px 5px 0px rgba(217,217,217,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(217,217,217,1);
    box-shadow: 10px 10px 5px 0px rgba(217,217,217,1);
  }

  .custom-shadow{
    -webkit-box-shadow: 10px 10px 5px 0px rgb(250, 193, 170);
    -moz-box-shadow: 10px 10px 5px 0px rgb(250, 193, 170);
    box-shadow: 10px 10px 5px 0px rgb(250, 193, 170);
  }
</style>