<template>
    <div class="__header-cardapio">
        <div class="row">
          <div class="col-10 title-container">
            <div class="title text-monospace"><strong>Cardápio</strong></div>
          </div>
          <div class="col-2 button-container" >
            <div><button @click=" newMenu" class="btn btn-primary">Novo</button></div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HomeHeader',
  methods:{
    newMenu(){
      this.$router.push('/cardapio/new');
    }
  }
}
</script>

<style lang="scss" scoped>

  .btn-primary,  .bg-primary{
    background-color: #FF7020 !important;
    border-color: #FF7020 !important;
  }
  .__header-cardapio{
    margin-bottom: 2vh;
    padding: 1vh;
    border-bottom: 1px solid #CDCDCD;
  }
  .button-container{
    padding-top: auto;
  }
  .row {
    --bs-gutter-x: 0;
  }
  .title{
     padding-top: auto;
     font-size: 3vh;
     min-height: 100%;
  }
</style>